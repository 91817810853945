import { useEffect } from "react";
import { allOrdersSubscriptionStore } from "@Ship/store/allOrdersSubscriptionStore";
import { useLazyQuery } from "@apollo/client";
import {
  MerchantProductsDocument,
  useWatchOrdersSubscription,
  usePurchaseHistoryQuery,
  useMeQuery,
} from "../../../generated/graphql";

let manualRefetchAllOrders: () => void = () => {};

const AllOrdersSubscriptionManager = () => {
  const setPurchaseHistoryList = allOrdersSubscriptionStore(
    (state: unknown) =>
      (state as { setPurchaseHistoryList: any }).setPurchaseHistoryList
  );
  const setAllProducts = allOrdersSubscriptionStore(
    (state: unknown) => (state as { setAllProducts: any }).setAllProducts
  );
  const setIsLoading = allOrdersSubscriptionStore(
    (state: unknown) => (state as { setIsLoading: any }).setIsLoading
  );
  const setError = allOrdersSubscriptionStore(
    (state: unknown) => (state as { setError: any }).setError
  );

  const { data: meData } = useMeQuery();

  const {
    data: purchaseHistoryList,
    loading: purchaseHistoryLoading,
    error: purchaseHistoryError,
    refetch: refetchPurchaseHistory,
  } = usePurchaseHistoryQuery({
    variables: {
      status: "completed",
      rows: 1000,
      page: 1,
    },
  });

  const {
    data: purchaseHistoryListRefunds,
    loading: purchaseHistoryLoadingRefunds,
    error: purchaseHistoryErrorRefunds,
    refetch: refetchPurchaseHistoryRefunds,
  } = usePurchaseHistoryQuery({
    variables: {
      status: "refund",
      rows: 1000,
      page: 1,
    },
  });

  const [getMerchantProducts] = useLazyQuery(MerchantProductsDocument, {
    onCompleted: (data) => {
      console.log("Data", data);
      const businessId =
        data.patron.merchantProducts.products[0]?.business?.businessId;
      if (businessId) {
        setAllProducts(businessId, data.patron.merchantProducts.products);
      }
    },
    onError: (error) => {
      console.error("Error fetching MerchantProducts:", error);
      console.error("GraphQL Errors:", error.graphQLErrors);
      console.error("Network Error:", error.networkError);
    },
  });

  // Helper function to fetch merchant products
  const fetchMerchantProducts = async () => {
    // Combine both lists for business ID extraction
    const completedOrders =
      purchaseHistoryList?.patron?.queryPurchases?.data || [];
    const refundOrders =
      purchaseHistoryListRefunds?.patron?.queryPurchases?.data || [];
    const allOrders = [...completedOrders, ...refundOrders];

    if (allOrders.length > 0) {
      const uniqueBusinessIds = Array.from(
        new Set(allOrders.map((order: any) => order?.businessId))
      );

      for (const businessId of uniqueBusinessIds) {
        if (businessId) {
          await getMerchantProducts({
            variables: {
              businessId: parseInt(businessId, 10),
              page: 1,
              rows: 1000,
            },
          });
        }
      }
    }
  };

  manualRefetchAllOrders = async () => {
    console.log("Manual refetch initiated");
    setIsLoading(true); // Set loading state

    try {
      // Refetch purchase history and refunds
      const [historyResult, refundsResult] = await Promise.all([
        refetchPurchaseHistory(),
        refetchPurchaseHistoryRefunds(),
      ]);

      // Extract all business IDs from both query results
      const completedOrders =
        historyResult.data?.patron?.queryPurchases?.data || [];
      const refundOrders =
        refundsResult.data?.patron?.queryPurchases?.data || [];
      const allOrders = [...completedOrders, ...refundOrders];

      // Get unique business IDs
      const uniqueBusinessIds = Array.from(
        new Set(allOrders.map((order: any) => order?.businessId))
      ).filter(Boolean); // Filter out any undefined/null values

      console.log("Got unique business IDs:", uniqueBusinessIds);

      // Fetch products for each business in sequence to ensure all requests complete
      for (const businessId of uniqueBusinessIds) {
        try {
          await getMerchantProducts({
            variables: {
              businessId: parseInt(businessId, 10),
              page: 1,
              rows: 1000,
            },
          });
          console.log(
            `Successfully fetched products for business ${businessId}`
          );
        } catch (error) {
          console.error(
            `Error fetching products for business ${businessId}:`,
            error
          );
        }
      }
    } catch (error) {
      console.error("Error during manual refetch:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Only update the state when both queries have completed
    if (purchaseHistoryList || purchaseHistoryListRefunds) {
      console.log(
        "Purchase History Data triggered by websocket:",
        purchaseHistoryList?.patron?.queryPurchases?.data
      );
      console.log(
        "Refund Purchase History Data:",
        purchaseHistoryListRefunds?.patron?.queryPurchases?.data
      );

      // Combine both lists
      const completedOrders =
        purchaseHistoryList?.patron?.queryPurchases?.data || [];
      const refundOrders =
        purchaseHistoryListRefunds?.patron?.queryPurchases?.data || [];

      // Merge the two arrays
      const combinedOrders = [...completedOrders, ...refundOrders];

      // Update the store with the combined list
      setPurchaseHistoryList(combinedOrders);
    }
  }, [purchaseHistoryList, purchaseHistoryListRefunds, setPurchaseHistoryList]);

  useWatchOrdersSubscription({
    variables: {
      patronId: parseInt(meData?.me?.id ?? "1", 10),
    },
    onData: (data: any) => {
      try {
        console.log("New order data websocket:", data);
        // Refetch both queries when a new order comes in
        refetchPurchaseHistory();
        refetchPurchaseHistoryRefunds();
      } catch (error) {
        console.error("Error refetching purchase history:", error);
      }
    },
  });

  useEffect(() => {
    // Set loading state to true if either query is loading
    setIsLoading(purchaseHistoryLoading || purchaseHistoryLoadingRefunds);

    // Set error if either query has an error
    setError(purchaseHistoryError || purchaseHistoryErrorRefunds);

    // Only fetch merchant products when at least one of the queries has completed
    if (!purchaseHistoryLoading || !purchaseHistoryLoadingRefunds) {
      fetchMerchantProducts();
    }
  }, [
    purchaseHistoryList,
    purchaseHistoryListRefunds,
    purchaseHistoryLoading,
    purchaseHistoryLoadingRefunds,
    purchaseHistoryError,
    purchaseHistoryErrorRefunds,
    getMerchantProducts,
    setIsLoading,
    setError,
  ]);

  return null; // This component doesn't render anything itself
};

export { manualRefetchAllOrders };

export default AllOrdersSubscriptionManager;
